// export enum LinkType {
//   blank = "_blank",
//   self = "self",
// }
// type LinkProps = {
//   url: string
//   target: LinkType
// }
import { isSandbox } from "./base"

export enum Links {
  DISCORD = "https://discord.com/invite/nzZrUAcUcK",
  DISCORD1 = "https://discord.gg/AquRPHkEth",
  DISCORD2 = "https://discord.gg/V28wrqqA3C",
  DISCORD3 = "https://discord.gg/wvBbewGYrA",
  INSTAGRAM = "https://www.instagram.com/cryptobots.me/",
  FB = "https://www.facebook.com/cryptobotsgame",
  LINKEDIN = "https://www.linkedin.com/company/crypto-bots",
  OPENSEA_CLASSIC = "https://opensea.io/collection/cryptobots",
  OPENSEA_GENESIS = "https://opensea.io/collection/cryptobotsgame",
  X2Y2_GENESIS = "https://x2y2.io/collection/cryptobotsgame/items",
  TWITTER = "https://twitter.com/cryptobots_game",
  TELEGRAM = "https://t.me/cryptobotsgame",
  MEDIUM = "https://medium.com/@cryptobots",
  STAKING_OLD = "https://old.cryptobots.me/staking",
  STAKING = "/staking",
  COMPANION = "/companion",
  PREREGISTRATION = "/pre-registration",
  REFERRALS_GUIDE = "/referrals-guide",
  _ABOUT = "/about",
  CLASSIC = "/classic",
  FAQ = "/faq",
  PRIVACY = "/privacy",
  DISCLAIMER = "/disclaimer",
  COOKIES = "/cookies",
  CONTACT = "/contact",
  TEAMS = "/teams",
  CREATE_TEAM = "/teams/create",
  // UPDATE_TEAM = "/teams/{id}", // TODO dynamic
  CREATE_ALLIANCE = "/alliance/create",
  DEMO = "https://demo.cryptobots.me/",
  WHITEPAPPER = "https://gitbook.cryptobots.me/cryptobots/",
  READ_SHIPS = "https://medium.com/@cryptobots/spaceships-utility-and-manufacturing-90e0001c7d09",
  COMPANIONS = "https://x2y2.io/collection/cbcompanions/items",
  BATTLE_HOST = "/battle",

  AUTH = "/auth",
  INVENTORY = "/inventory",
  MARKETPLACE = "/marketplace",
  TOP_PLAYERS = "/top-players",
  TOP_ALLIANCES = "/top-alliances",
  LEADERBOARD = "/leaderboard",
  GAME = "/game",
  BOT = "/bot/",
  DISCOVERY = "/game",
  ALLIANCE = "/alliance",
  ALLIANCE_SEARCH = "/alliance/search",
  ALLIANCE_EDIT = "/alliance/edit",
  REWARDS = "/rewards",
  MANUFACTURING = "/manufacturing",

  BATTLE_SELECT = "/battle-select",
  PROFILE = "/profile",
}

export const WSS_BATTLE = isSandbox
  ? "wss://battles-sandbox.botsbits.io/socket"
  : "wss://battles-staging.botsbits.io/socket"
// export const internal: LinkProps[] = [
//   { url: "", target: LinkType.self },
//   { url: "", target: LinkType.self },
//   { url: "", target: LinkType.self },
// ]

// export const external: LinkProps[] = [
//   { url: "", target: LinkType.blank },
//   { url: "", target: LinkType.blank },
//   { url: "", target: LinkType.blank },
// ]
