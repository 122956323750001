import React from "react"
import { trackHeaderSocialClick } from "services/analytics"

import { Box } from "../shared/box"
import { Button } from "../shared/landing/button"
import { Link } from "../shared/link"
import { LogoText } from "../shared/logo-text"
import type { ActionButton } from "./domain"
import { socialBtns } from "./utils"

const mobileSocialBtns = [socialBtns[0], socialBtns[1]]

type HeaderProps = {
  fixed?: boolean
  showMenu?: boolean
  showMobileMenu?: boolean
  showLogoName?: boolean
  actionBtn?: ActionButton
  alwaysShowActionButton?: boolean
  className?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Header({ fixed = false, showLogoName = true, alwaysShowActionButton = false, className }: HeaderProps) {
  const lastSocialBtnNumber = socialBtns.length - 1

  return (
    <>
      <Box
        row
        center
        className={[
          `w-full z-40 backdrop-blur-2xl ${
            fixed ? "fixed top-0 left-0 bg-dark-space/80 drop-shadow-xl" : "absolute bg-black/10"
          }`,
          className,
        ].join(" ")}
      >
        <Box row center className="h-[60px] w-full my-3">
          <Box row center className={`w-full max-w-[1200px] text-white justify-between px-4 md:px-12 lg:px-6`}>
            <Box row className="items-center w-auto">
              <div>
                <LogoText place="header" showLogoName={showLogoName} />
              </div>
            </Box>
            <Box row center className="gap-6">
              {!alwaysShowActionButton && (
                <Box row className="items-end w-auto flex lg:hidden">
                  {mobileSocialBtns.map((b, i) => (
                    <Link
                      href={b.href}
                      key={i}
                      target={b.target === "_blank" ? "_blank" : "_self"}
                      className={"ml-3"}
                      withRedirect
                      onClick={() => trackHeaderSocialClick(b.eventName)}
                    >
                      <Button
                        type="secondary"
                        noPadding
                        noOverlay
                        className={`h-[42px] ${i !== lastSocialBtnNumber ? `w-[42px]` : ""}`}
                      >
                        {b.content}
                      </Button>
                    </Link>
                  ))}
                </Box>
              )}
            </Box>
            <Box row className="items-center w-auto hidden lg:flex">
              {socialBtns.map((b, i) => (
                <Link
                  href={b.href}
                  key={i}
                  target={b.target}
                  className={i > 0 ? "ml-3" : i === lastSocialBtnNumber ? "ml-5" : ""}
                  withRedirect
                  onClick={() => trackHeaderSocialClick(b.eventName)}
                >
                  <Button type="secondary" noPadding={true} noOverlay={true} className="h-[42px] w-[42px]">
                    {b.content}
                  </Button>
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
