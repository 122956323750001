import { Links } from "config/links"

import { Discord } from "components/shared/icons/discord"
import { Telegram } from "components/shared/icons/telegram"
import { Twitter } from "components/shared/icons/twitter"

import type { MenuLinks, SocialBtns } from "./domain"

export const links: MenuLinks[] = [
  // { label: "__staking", url: "/staking" },
  // { label: "The Game", url: Links.ABOUT }, // /game?
  { label: "Classic Collection", url: Links.CLASSIC, target: "_self", eventName: "classic_collection" },
  { label: "Battle for Lands", url: Links.PREREGISTRATION, target: "_blank", eventName: "preregistration_page" },
  // { label: "Staking", url: Links.STAKING, target: "_blank", eventName: "staking" },
  // { label: "FAQ", url: Links.FAQ, target: "_self", eventName: "faq" },
]

export const socialBtns: SocialBtns[] = [
  { content: <Discord />, href: Links.DISCORD, square: true, target: "_blank", eventName: "discord" },
  { content: <Twitter />, href: Links.TWITTER, square: true, target: "_blank", eventName: "twitter" },
  { content: <Telegram />, href: Links.TELEGRAM, square: true, target: "_blank", eventName: "telegram" },
]
