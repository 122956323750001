import type { NextPage } from "next"
import type { AppProps } from "next/app"
import React from "react"

import { Layout } from "components/layout"
import { HtmlHead } from "components/layout/head"
import { CookiesPopupSpawn } from "components/modals/cookies"
import { WrongNetworkPopupSpawn } from "components/modals/wrong-network"

import "../styles/globals.scss"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  token: string | null
}

function MyApp(page: AppPropsWithLayout) {
  const getLayout = page.Component.getLayout ?? (page => <Layout>{page}</Layout>)

  //TODO move to new layout
  return (
    <>
      <HtmlHead />
      <CookiesPopupSpawn />
      {page.Component.getLayout && <WrongNetworkPopupSpawn />}
      {getLayout(<page.Component {...page.pageProps} />)}
    </>
  )
}

export default MyApp
