import { trackFooterLogoClick, trackHeaderLinkClick } from "services/analytics"
import { Box } from "../box"
import { LogoDark } from "../icons/logo-dark"
import { Link } from "../link"
import { Text } from "../text"

export function LogoText({ place, showLogoName = true }: { place: "header" | "footer"; showLogoName?: boolean }) {
  const handleClick = () => {
    if (place === "header") return trackHeaderLinkClick("home")
    if (place === "footer") return trackFooterLogoClick()
  }

  return (
    <Link href="/" withRedirect onClick={handleClick}>
      <Box row center>
        <LogoDark />
        {showLogoName && <Text className="uppercase font-bold ml-2">Cryptobots</Text>}
      </Box>
    </Link>
  )
}
