import NextLink from "next/link"

export type LinkProps = {
  href?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  target?: "_blank" | "_self"
  children: React.ReactNode | string
  className?: string
  withRedirect?: boolean
  highlightOnHover?: boolean
  disabled?: boolean
}

export function Link({
  href = "",
  target,
  children,
  disabled = false,
  onClick,
  highlightOnHover = true,
  className = "",
  withRedirect = false,
}: LinkProps) {
  const classNames = []

  if (highlightOnHover) {
    classNames.push("hover:text-primary")
  }
  if (disabled) {
    classNames.push("pointer-events-none")
  }
  classNames.push(className)

  let props = {}
  if (onClick) {
    props = {
      onMouseDown: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!onClick) return
        !withRedirect && event.preventDefault()
        onClick(event)
      },
    }
  }

  return (
    <NextLink href={href}>
      <a target={target} className={classNames.join(" ")} {...props}>
        {children}
      </a>
    </NextLink>
  )
}
