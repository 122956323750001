import React from "react"
import { Box } from "../shared/box"
import { Footer } from "../footer"
import { Header } from "../header"

type LayoutProps = {
  children: React.ReactNode
}

export const maxWidth = 1200

export function Layout({ children }: LayoutProps) {
  return (
    <Box center className="min-h-[100vh] h-fit relative">
      <Box full className="bg-space absolute top-0 left-0 -z-[2]" />
      <Box full className="flex-1">
        <Header />
        {children}
      </Box>
      <Footer maxWidth={maxWidth} />
    </Box>
  )
}
