import { useEthers } from "@usedapp/core"

import { useMounted } from "components/hooks/use-mounted"

import { Box } from "components/shared/box"
import { MetaMask } from "components/shared/icons/metamask"
import { Rotating } from "components/shared/icons/rotating"
import { Text } from "components/shared/text"
import { TextPrimary } from "components/shared/text-primary"

export function WrongNetworkPopupSpawn() {
  const mounted = useMounted()
  const { account, chainId } = useEthers()

  if (!mounted || !account || chainId === 4) {
    return null
  }

  return (
    <Box center className="fixed inset-0 bg-black/60 backdrop-blur-3xl z-50">
      <Box row className="left-0 bottom-0 w-full max-w-[60vw] md:max-w-[50vw] z-40 p-6 ">
        <Box
          full
          center
          className="gap-6 backdrop-blur-md bg-black/40 p-6 rounded-md border-[1px] border-gray-200/20 drop-shadow"
        >
          <MetaMask w={48} h={48} />
          <Text className="text-h4 text-center">
            Please, switch the network to <TextPrimary>Rinkeby</TextPrimary>{" "}
          </Text>
          <Rotating className="animate-spin w-[1.5rem]" />
        </Box>
      </Box>
    </Box>
  )
}
