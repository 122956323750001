import React from "react"

export type TextFontSize =
  | "sm"
  | "base"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "table"
  | "tiny"
  | "light"
  | "title"
  | "lg"
export type TextColor = "default" | "primary" | "secondary" | "white" | "deepPrimary"
export type TextFontWeight = "regular" | "medium" | "semibold" | "bold" | null
export type TextAs = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span"

export type TextProps = {
  children: React.ReactNode
  color?: TextColor
  weight?: TextFontWeight
  size?: TextFontSize
  as?: TextAs
  className?: string
  center?: boolean
}

export function Text({ children, color = "white", weight, size, as = "p", className = "", center }: TextProps) {
  const classNames = []
  classNames.push(getColorClasses(color))
  if (weight) {
    classNames.push(getTextFontWeightClasses(weight))
  }
  if (size) {
    classNames.push(getFontSizeClasses(size))
  }
  if (center) {
    classNames.push("text-center")
  }
  classNames.push(className)
  const Tag = as
  return <Tag className={classNames.join(" ")}>{children}</Tag>
}

const getTextFontWeightClasses = (type: TextFontWeight) => {
  switch (type) {
    case "regular":
      return "font-regular"
    case "medium":
      return "font-medium"
    case "semibold":
      return "font-semibold"
    case "bold":
      return "font-bold"
    default:
      return ""
  }
}

const getColorClasses = (type: TextColor) => {
  switch (type) {
    case "default":
      return "text-text"
    case "primary":
      return "text-primary"
    case "deepPrimary":
      return "text-deepPrimary"
    case "secondary":
      return "text-secondary"
    case "white":
      return "text-white"
    default:
      return ""
  }
}

const getFontSizeClasses = (size: TextFontSize) => {
  switch (size) {
    case "tiny":
      return "text-tiny"
    case "sm":
      return "text-sm"
    case "light":
      return "text-light"
    case "table":
      return "text-table"
    case "base":
      return "text-base"
    case "title":
      return "text-title"
    case "lg":
      return "text-lg"
    case "h5":
      return "text-h5"
    case "h4":
      return "text-h4"
    case "h3":
      return "text-h3"
    case "h2":
      return "text-h2"
    case "h1":
      return "text-h1"
    default:
      return ""
  }
}
