import getConfig from "next/config"

const { publicRuntimeConfig } = getConfig()

const devEnv = publicRuntimeConfig.DEV_ENV || "dev"

export const isDev = devEnv === "dev"
export const isSandbox = devEnv === "sandbox"
export const isProd = devEnv === "prod"

export const isMainnet = isProd
export const FB_PIXEL_ID = "2030057823840789"
export const GA_TRACKING_ID = "UA-212576813-1"
export const tokenAddress = isMainnet
  ? "0x4dF461F8c22c0bfB40a51560597D5b946d869d5c"
  : "0xC396C29D2fB0B1B21af86D53E66F1512edf825e1"

export const infuraId = isMainnet ? "9aa3d95b3bc440fa88ea12eaa4456161" : "dadd521501e84e76910f306d29785156"
