const isProd = process.env.NODE_ENV === "production"

type WindowExtended = Window &
  typeof globalThis & {
    fbq: (action: string, name: string, options: unknown) => void
    gtag: (name: string, category: string, options: unknown) => void
  }

export function trackGa(name: string, category: string, label: string) {
  if (isProd) {
    ;(window as WindowExtended).gtag("event", name, {
      event_category: category,
      event_label: label,
    })
  }
}

export const trackFbq = (action: string, name: string, options = {}) => {
  if (isProd) {
    ;(window as WindowExtended).fbq(action, name, options)
  }
}
