import { trackFbq, trackGa } from "services/tracking"

// fbq
export const trackBuyNowToMP = () => trackFbq("track", "InitiateCheckout")

export const trackGASocialClick = (name: string, label: AboutSectionEventLabel) =>
  trackGa(`social_${name}`, "social", label)

// header
export const trackHeaderSocialClick = (name: string) => trackGASocialClick(name, "header")
export const trackHeaderLinkClick = (name: string) => trackGa(`click_${name}`, "user-engagement", "header")

// footer
export const trackFooterLogoClick = () => trackGa(`click_home`, "user-engagement", "footer")
export const trackFooterClick = (
  category: "click_privacy" | "click_disclamer" | "click_cookies" | "contacts" | "click_companion",
) => trackGa(category, "user-engagement", "footer")
export const trackFooterSocialClick = (name: string) => trackGASocialClick(name, "footer")

// bots
export const trackBuyNowBot = (id: "01" | "02" | "03" | "04" | "05") =>
  trackGa("click_buy-offered-__bot", "secondary_marketplace", `selected-bot_${id}`)
export const trackSeeAllClick = () => trackGa("click_see-all-bots", "user-engagement", "promo-selection")

// early access
export const trackEarlyAccessClick = () => trackGa("click_preregistration_page", "pre-registration", "p2e-info")

export type StakingEventLabel = "p2e-info" | "genesis_bots" | "genesis_bots_benefits"
export const trackStakingClick = (label: StakingEventLabel) => trackGa("click_staking_page", "user-engagement", label)

// about section
export type AboutSectionEventLabel = "about" | "onpage" | "cryptobots_gameplay" | "header" | "footer"
export const trackPlayDemoClick = (label: AboutSectionEventLabel) =>
  trackGa("click_play-demo", "user-engagement", label)
export const trackWhitepaperClick = (label: AboutSectionEventLabel) =>
  trackGa("click_whitepapper", "user-engagement", label)
export const trackWatchVideoClick = (label: AboutSectionEventLabel) => trackGa("start_video", "user-engagement", label)

export type ConnectMetamaskEventLabel = "gift_popup" | "onpage"
export const trackConnectMetamaskClick = (label: ConnectMetamaskEventLabel) =>
  trackGa("connect_metamask_click", "metamask", label)
export const trackConnectMetamaskSuccess = (label: ConnectMetamaskEventLabel) =>
  trackGa("connect_metamask_succes", "metamask", label)
export const popupConnectMetamaskClick = () => trackGa("connect_metamask_click", "metamask", "gift_popup")
export const popupConnectMetamaskSuccess = () => trackGa("connect_metamask_succes", "metamask", "gift_popup")
export const popupCompanionsClick = () => trackGa("click_marketplace_companions", "secondary_marketplace", "gift_popup")
export const popupSkipClick = () => trackGa("click_skip-gift-popup", "user-engagement", "gift_popup")

// preregistration page
export type PreregPageEvent = "header" | "onpage" | "nft_lands" | "sign_up_testnet"
export const trackPreregPageEvent = (label: PreregPageEvent) =>
  trackGa("click_preregistration_page", "pre-registration", label)
export type SocialNetworkName = "twitter" | "discord"
export const trackPreregPopupSocialClick = (socialName: SocialNetworkName) =>
  trackGa(`click_${socialName}`, "pre-registration", "popup")
export const trackPreregConfirmClick = () => trackGa(`click_confirm`, "pre-registration", "popup")
export const trackPreregCompleted = () => trackGa("click_completed", "pre-registration", "popup")
export const trackReferalGuideClick = () => trackGa(`click_referral-program`, "user-engagement", "onpage")

// spaceships
export const trackReadMoreClick = () => trackGa("click_read_more", "user-engagement", "spaceships")

export type TeamLinkedinNames =
  | "simon"
  | "alex"
  | "serg"
  | "konstantin"
  | "dmitri"
  | "aliaksandr"
  | "daniil"
  | "ekaterina"
export const trackTeamLinkedinClick = (name: TeamLinkedinNames) =>
  trackGa("click_link_team", "user-engagement", `team_${name}`)

export type BuyNowEventLabel =
  | "promo-card"
  | "genesis-benefits"
  | "leasing-info"
  | "p2e-info"
  | "developer-info"
  | "popup"
  | "gift_popup"
  | "cryptobots_gameplay"
  | "genesis_bots"
  | "genesis_bots_benefits"
export const trackBuyNowClick = (eventLabel: BuyNowEventLabel) =>
  trackGa("click_buy-bots", "secondary_marketplace", eventLabel)
